import type { AnalyticsCallOptions } from 'firebase/analytics';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { child, get, getDatabase, ref, set } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig, getValue } from 'firebase/remote-config';

import remoteConfigDefaults from './remote_config_defaults.json';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: 'thimblerr-web-app.firebaseapp.com',
  projectId: 'thimblerr-web-app',
  storageBucket: 'thimblerr-web-app.appspot.com',
  messagingSenderId: '753220584461',
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  databaseURL:
    'https://thimblerr-web-app-default-rtdb.asia-southeast1.firebasedatabase.app',
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const db = getFirestore(app);

if (import.meta.env.DEV) {
  remoteConfig.settings.minimumFetchIntervalMillis = 60000;
} else {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

remoteConfig.defaultConfig = remoteConfigDefaults;

export const getRemoteConfigData = (key: string) => {
  try {
    return JSON.parse(getValue(remoteConfig, key).asString());
  } catch (error) {
    console.log(error);
  }
};

export function logConfigEvent(
  eventName: string,
  eventParams?: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions,
): void {
  if (import.meta.env.VITE_APP_ENVIRONMENT === 'production')
    logEvent(analytics, eventName, eventParams, options);
}

export function addEmailLead(email: string) {
  if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
    const dbRef = ref(database);

    get(child(dbRef, 'email_lead/'))
      .then((snapshot) => {
        const emailArray: string[] = snapshot.val() || [];
        if (!emailArray.includes(email)) {
          emailArray.push(email);
          set(ref(database, 'email_lead/'), emailArray);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
